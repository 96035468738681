
 
  /* .pagewidth {
    width: calc(200vh);
  } */
  
  
  /* .pageheight {
    height: calc(100vh - 95px);
  }
   */
  
  /* Content Section  */
  /* .content {
    padding: 30px;
    background: #F6F6FC;
  }
   */
  /* .middle-section {
    height: calc(100vh - 95px);
    overflow: auto;
    background: #F6F6FC;
  } */
  
  /* .content {
    flex: 3;
    padding: 20px;
  }
   */
  .questions {
    display: flex;
    /* padding: 10px 0px; */
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .questionCard {
    border-radius: 5px;
    background: #FFF;
    display: flex;
    padding: 35px 30px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .edit-module {
    flex-direction: row;
    align-items: center !important;
  }
  
  .question {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  
  .input-wrapper {
    gap: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    /* margin-top: 10px; */
  }
  
  .add-choice-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .add-choice-button {
    cursor: pointer;
    display: flex;
    padding: 10px;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #E9ECEF;
    background: #F6F6FC;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    color: #ADB5BD;
    justify-content: center;
  }
  
  .left-section {
    flex: 1.3;
    background-color: white;
    border-right: 2px solid #EEE;
  }
  
  .middle-section {
    padding: 30px;
    background: #F6F6FC;
    /* height: calc(100vh - 145px);
    overflow: auto; */
    flex: 3;
  }
  
  .right-section,
  .logicMiddle {
    flex: 1.5;
    background-color: #F6F6FC;
    border-left: 2px solid #EEE;
  }
  
  .logicMiddle {
    padding: 30px;
    background: #ffffff;
    height: calc(100vh - 90px);
    overflow: auto;
    flex: 4;
  }
  
  .heading-tab-outer {
    display: flex;
    padding: 10px 15px 0px 15px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #C4CCF8;
  }
  
  .nav-item-dt a {
    border: 0px !important;
  }
  
  .nav-link-dt.active {
    border-bottom: 3px solid #319EFF !important;
    color: #0048B2 !important;
    background: transparent !important;
  }
  
  .nav-link-dt {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 16px;
    transition: 0.3s;
    font-size: 17px;
    color: #ADB5BD !important;
  }
  
  
  .card-navigator {
    display: flex;
    padding: 30px 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  
  .card-navigator .previous {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    color: #319EFF;
  }
  
 .card-navigator .previous.disabled {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    color: #ADB5BD;
  }

  .card-navigator .next.disabled {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    color: #ADB5BD;
  }
  
  .card-navigator .previous,
  .card-navigator .next {
    cursor: pointer;
  }
  
  .right-section .nav-link.active {
    border-bottom: 3px solid #000 !important;
    color: #001F4D !important;
  }
  
  .editLogic {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
  }
  
  .logicWrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-self: stretch;
  }
  
  .logicHeadText {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #001F4D;
  }
  
  .removeLogic {
    display: flex;
    font-size: 12px;
    align-items: center;
    color: #C03221;
    margin-right: 5px;
    cursor: pointer;
  }
  
  .rules-head .accordion-button {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
    color: #8A92A6;
  
  }

  
  
  .accordion-button:not(.collapsed) {
    color: #8A92A6 !important;
    background-color: #ffffff !important;
  }
  
  .logic-edit .accordion-item {
    border-radius: 5px !important;
    border: 1px solid #E3E3E3;
    background: #FFF;
  }
  
  .accordion-body {
    color: #8A92A6 !important;
  }
  
  .logic-edit .accordion-button {
    border-radius: 5px 5px 0px 0px !important;
  }
  
  .pad-right {
    padding-right: 5px;
  }
  
  .pad-left {
    padding-left: 5px;
  }
  
  .addCond {
    color: #319EFF;
    display: flex;
    font-size: 13px;
    font-weight: 400;
    line-height: 130%;
    cursor: pointer;
  }
  
  .corrIcon01 {
    position: relative;
    top: -1px;
  }
  
  .mar-r5 {
    margin-right: 5px;
  }
  
  .addRuleButton button {
    border-radius: 5px;
    border: 1px solid #E9ECEF;
    background: #319EFF;
  }
  
  .ruleColor {
    color: white;
  }
  
  .buttonWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  
  .buttonwrap-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
  }
  
  .delete-Button {
    display: flex;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #B24400;
    background: #FFF;
  }
  
  .buttonbatch {
    display: flex;
    width: 91px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  
    font-size: 13px;
    font-weight: 400;
    line-height: 130%;
  }
  
  .savebutton {
    border-radius: 4px;
    border: 1px solid #319EFF;
    background: #319EFF;
    color: white;
  }
  
  .cancelbutton {
    border-radius: 4px;
    border: 1px solid #C03221;
    color: #C03221;
    background: transparent;
  }
  
  button.accordion-button.collapsed {
    border-radius: 5px !important;
  }
  
  .questSec-select {
    padding: 14px 21px !important;
    font-size: 14px !important;
  }
  
  .tab-content {
    color: #8A92A6 !important;
  }
  
  .white-bg {
    background-color: white;
  }
  
  .br-5 {
    border-radius: 5px;
  }
  
  textarea.font-13.form-control {
    color: #232D42;
    font-size: 13px;
    font-weight: 400;
    line-height: 130%;
  }
  
  .manswer {
    color: #8A92A6;
    font-size: 13px;
    font-weight: 400;
    line-height: 130%;
  }
  
  .fixcheck {
    display: flex;
    align-items: center;
  }
  
  
  .accordion_sidebar .accordion-item,
  .accordion_sidebar .accordion-item .accordion-button,
  .accordion_sidebar .accordion-item .accordion-button:not(.collapsed) {
    border-radius: 5px;
    /* border: 1px solid #E9ECEF; */
    background: #F6F6FC !important;
    padding: 7px;
    color: #8A92A6;
  }
  
  .accordion_sidebar .accordion-button:not(.collapsed),
  .accordion_sidebar .accordion-button {
    box-shadow: none !important;
  }
  
  .accordion_sidebar .accordion-button:focus {
    border: 0px;
  }
  
  .accordion_sidebar .accordion-item {
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .accordion_sidebar .accordion-body {
    margin-bottom: 10px;
    padding: 5px;
  }
  
  .accordion_sidebar .accordion-item {
    border-radius: 5px !important;
    border: 1px solid #E9ECEF;
  }
  
  .text-settings {
    color: #232D42;
    font-size: 13px;
    font-weight: 400;
    line-height: 130%;
  }
  
  .SettingsWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    padding-bottom: 7px;
  }
  
  .form-check-input:checked {
    border-color: #C4CCF8;
    background-color: #C4CCF8;
  }
  
  .form-check-input {
    border-color: #EEEEEE;
    background-color: #EEEEEE;
  }
  
  .tabcontent-overflow {
    /* height: calc(100vh - 145px); */
    overflow: auto;
  }
  
  .correctsubmit {
    position: relative;
    top: 1px;
  }
  
  .questionlinks {
    display: flex;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    cursor: pointer;
  }
  
  .questionlinks.questionActive {
    background: #E9ECEF;
  }
  
  .questions-text {
    color: #232D42;
    font-size: 13px;
    font-weight: 400;
    line-height: 130%;
  }
  
  .next.active {
    color: #319EFF !important;
  }
  
  .next.active svg path {
    stroke: #319EFF !important;
  }
  
  .choosemr-3 {
    margin-right: 7px;
  }
  
  .input-edit {
    position: absolute;
    right: 10px;
    top: 1px;
    cursor: pointer;
  }
  
  .input-edit svg {
    position: relative;
    top: 5px;
  }
  
  .input-field.form-control {
    border-radius: 5px !important;
  }
  
  .questions .input-field.form-control {
    background: #F6F6FC;
  }
  
  .ConfigureTab .btn-outline-secondary {
    display: flex;
    /* padding: 10px 10px; */
    justify-content: center;
    align-items: center;
    gap: 5px;
    /* flex: 1 0 0; */
    /* font-size: 16px; */
    border: 1px solid #8A92A6;
    font-style: normal;
    /* font-weight: 400; */
    border-radius: 5px;
    background-color: transparent;
    /* color: #8A92A6; */
    cursor: pointer;
    transition: border-color 0.3s;
    /* width: 100%; */
  }
 
  
  .flex-gap {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
  
  .flex-gap2 {
    display: flex;
    gap: 10px;
    flex-direction: row;
  }
  
  .addFields {
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-top: 10px;
  }
  
  .fieldHeadings {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #8A92A6;
  }
  
  .ConfigureTab .btn-outline-secondar {
    display: flex;
    padding: 10px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    font-size: 16px;
    border: 1px solid #8A92A6;
    font-style: normal;
    font-weight: 400;
    border-radius: 5px;
    background-color: transparent;
    color: #8A92A6;
    cursor: pointer;
    transition: border-color 0.3s;
  }
  
  .button-TabGroup .buttonwidth-100 {
    width: 100%;
  }
  
  .btn:hover {
    background: #319EFF !important;
    color: #ffff !important;
  }
  
  .btn:hover svg path {
    stroke: #ffff !important;
  }
  
  .headingDecision-txt {
    color: #232D42;
    font-size: 16px;
    font-weight: 500;
    line-height: 175%;
  }
  
  .headheading {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .buttonHeading button {
    display: flex;
    padding: 9px 24px;
    justify-content: center;
    align-items: center;
  
    border-radius: 4px;
    border: 1px solid #ADB5BD;
    width: 130px;
    background: transparent;
  }
  
  .buttonHeading {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
  }
  
  .buttonHeading .savebutton {
    background: #319EFF !important;
    color: white;
  }
  
  .flex-centre01 {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .flex-centre02 {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .number-tab {
    display: flex;
    width: 30px;
    height: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: #3A57E8;
    color: white;
  }
  
  .top01 {
    position: relative;
    top: -1px;
  }
  
  .tabGroup.nav .nav-link.active {
    border: none !important;
  }
  
  .tabGroup {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  
  .addbutton {
    display: flex;
    width: 30px;
    height: 30px;
    padding: 3px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: #1AA053;
    cursor: pointer;
  }
  
  .questionCard {
    display: flex;
    padding: 15px 30px 25px 15px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
  
    border-radius: 7px;
    background: #FFF;
    box-shadow: 0px 8px 32px 0px rgba(0, 16, 41, 0.05);
  }
  
  .card-inner-head {
    display: flex;
    padding-left: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    width: 100%;
    /* padding-bottom: 10px;
    border-bottom: 1px solid #EEE; */
  }
  
  .heading-cards {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .icons_heading-cards {
    display: flex;
    padding: 7px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  
    border-radius: 5px;
    background: #E9ECEF;
  }
  
  .heading0023 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-grow: 1;
    padding-bottom: 10px;
    border-bottom: 1px solid #EEE;
  }
  
  .customSelect select {
    padding: 0px !important;
    margin: 0px;
    border: none;
    width: 130%;
  }
  
  .flexHead {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
  
    color: #232D42;
    font-size: 16px;
    font-weight: 500;
    line-height: 175%;
  }
  
  .imagesCorrec img {
    width: 25px;
    height: 27px;
    opacity: 0.3;
  }
  
  .flexHead0043 {
    color: #232D42;
    font-size: 14px;
    font-weight: 400;
    line-height: 175%;
  }
  
  .draggableWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    align-self: stretch;
  }
  
  .draggable {
    width: 100%;
    cursor: move;
  }
  
  .AddSection-bottom {
    display: flex;
    align-items: center;
    gap: 10px;
  
    color: #6C757D;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 175%;
    cursor: pointer;
  }
  
  .eyebutn {
    width: 60px !important;
    padding: 10px !important;
    border: 1px solid #319EFF;
  }

  .margin-right-top-dt{
    margin-right: 1rem;
  }




/* add Dropdown */
.add_section {
  cursor: pointer;
  padding: 10px;
  border: none;
  border-radius: 4px;
}

.dropdown-content01 {
  /* display: none; */
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.section {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.section-outer .section{
  padding-top: 20px;
  padding-bottom: 20px;

}

ul {
  list-style: none;
  padding: 0px!important;
}

li {
  cursor: pointer;
}

/* Show dropdown content on click */
.dropdown-content01.active {
  display: block;
}

/* Dropdown UI */
.dropdown-content01{
  border-radius: 4px;
}
.section-outer{
  display: flex;
  justify-content: space-between;
  width: 500px;
  padding: 15px;
  border-radius: 4px;
}
.section-outer .section{
  border: 0px;
}
.section-outer .section h3{
  font-size: 12px;
  line-height: 20px;
  color: rgb(115, 115, 115);
  font-weight: 500;
  margin-top: 0px;
}

.section-outer .section li{
  font-size: 14px;
  color: rgb(38, 38, 39);
}
.add-options{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon-options-contain{
  padding: 5px 8px 8px 8px;
  position: relative;
  top: 0px;
  margin-right: 7px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #E9ECEF;
}
 

.question-select-text{
  font-size: 12px;
  margin-bottom: 10px;
}



.tabtext.textselected {
  color: #232D42;
}

.tabtext {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #ADB5BD;
}

.tab-edit-input{
  border: 1px solid #dcdcdc;
    border-radius: 5px;
    /* width: 100%; */
    height: 25px;
    color: var(--bs-gray-700);
}
.modal-title{
  width: 100%;
}

.heading-model {
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  color: #232D42;
  align-self: center;
}

.paragraph-model {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #232D42;
  align-self: center;
}

.model-body {
  display: flex;
  padding: 22px 30px;
  gap: 20px;
  flex-direction: column;
}

.hyperlink-model{
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #319EFF;
  align-self: center;
  margin-top: -25px;
  cursor: pointer;
}

.attachment-model{
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #319EFF;
  cursor: pointer;
}

