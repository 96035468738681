.heading-carsol {
    display: flex;
    flex-direction: column;

    .white-text {
        color: #FFFFFF;
        font-weight: 600;
        font-size: 50px;
        text-align: left;
    }

    .white-text2 {
        color: #FFFFFF;
        font-weight: 600;
        font-size: 60px;
        text-align: left;
    }

    .gray-text {
        color: #001F4D;
        font-weight: 600;
        font-size: 50px;
        text-align: left;
    }

}

.planimgContainer{
    display: flex;
    justify-content: flex-start;
    width: 95%;
}

.welcome-text {
    font-size: 1.625rem;
}

.btn-custome-color{
    --bs-btn-color: #fff !important;
    --bs-btn-bg: #369BFF !important;
    --bs-btn-border-color: #369BFF !important;
    --bs-btn-hover-color: #fff !important;
    --bs-btn-hover-bg: #2e84d9 !important;
    --bs-btn-hover-border-color: #2b7ccc !important;
    --bs-btn-focus-shadow-rgb: 84, 170, 255 !important;
    --bs-btn-active-color: #fff !important;
    --bs-btn-active-bg: #2b7ccc !important;
    --bs-btn-active-border-color: #2974bf !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    --bs-btn-disabled-color: #fff !important;
    --bs-btn-disabled-bg: #369BFF !important;
    --bs-btn-disabled-border-color: #369BFF !important;
}

.para-text {
    text-align: left;
}

.auth-full-bg {
    background-image: radial-gradient(circle at 50% 50%, #369BFF 0%, #3A57E8 100%);
    // border-radius: 0px 30px 30px 0px;
}

.outerWrap {
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(15px);
    border-radius: 20px;
    padding: 65px 80px 80px 80px;
}

.custom-calc {
    height: calc(100vh - 100px); //calculating the height diff 
    display: flex;
    align-items: center;
}

.auth-full-page-content {

    input[type=text],
    input[type=password],
    input[type=email] {
        padding: 12px 16px;
    }

    input[type=checkbox] {
        padding: 8px;
        margin-left: -2em;
        margin-top: 0.1em;
    }

    .input-group #password-addon {
        padding-right: 20px;
        padding-left: 20px;
        background: white;
        border: 1px solid var(--bs-input-border-color);
    }

    button[type=submit] {
        padding: 12px 16px;
    }

    .form-check {
        padding-left: 2em;
    }

    .width100 {
        width: 100%;
    }

    .centerTextCustom {
        text-align: center;
    }

    .centerCustom {
        align-self: center;
    }

    .maxWidthCustom {
        max-width: 500px;
    }

    .centerJustCustom {
        justify-content: center !important;
    }
}

.auth-full-page-content.centerJustCustom {
    justify-content: center !important;
}